











































































































































































































































































import { StakingViewModel } from "@/modules/staking/viewmodels/staking-viewmodel";
import { Observer } from "mobx-vue";
import VueScrollTo from "vue-scrollto";
import { Component, Vue, Provide, Ref } from "vue-property-decorator";
import { walletStore } from "@/stores/wallet-store";
import { blockchainHandler } from "@/blockchain/index";
import { confirmDialogController } from "@/components/confirm-dialog/confirm-dialog-controller";
import MonsterCard from "../components/monster-card.vue";
import { snackController } from "@/components/snack-bar/snack-bar-controller";
import SummonDialog from "@/modules/egg-hatch/dialog/summon-dialog.vue";
import { bigNumberHelper } from "@/helpers/bignumber-helper";
import { FixedNumber } from "@ethersproject/bignumber";

// Red -dragon
// green - treeman
// Angel - Yellow
// Shadow - Purple
// mermaid - Green

@Observer
@Component({
  components: {
    MonsterCard,
    SummonDialog,
  },
})
export default class Staking extends Vue {
  @Provide() vm = new StakingViewModel();
  walletStore = walletStore;

  @Ref("open-egg-video") video: any;
  controller = confirmDialogController;
  scrollOptions = {
    easing: "ease-in",
    lazy: false,
    force: true,
  };
  summoned = false;
  destroyed() {
    this.vm.destroy();
  }
  async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  // async standardSummon() {
  //   if (bigNumberHelper.gt(this.vm.standardTicketNo, FixedNumber.from("0"))) {
  //     this.vm.standardSummon();
  //   } else {
  //     snackController.error("You do not have any standard ticket!");
  //   }
  // }
  // async premiumSummon() {
  //   if (bigNumberHelper.gt(this.vm.premiumTicketNo, FixedNumber.from("0"))) {
  //     this.vm.premiumSummon();
  //   } else {
  //     snackController.error("You do not have any premium ticket!");
  //   }
  // }

  async confirmSummon() {
    //   this.summoned = false;
    //   if (this.vm.isPremiumSummon) {
    //     const spinResult = (await this.vm.premiumSpin()) as any;
    //     if (spinResult?.result) {
    //       this.vm.cancelSummonDialog();
    //       VueScrollTo.scrollTo("#video", 500, this.scrollOptions);
    //       await this.sleep(500);
    //       this.video.play();
    //       await this.sleep(2500);
    //       this.summoned = true;
    //       snackController.success(spinResult?.message);
    //     } else {
    //       snackController.error(spinResult?.message);
    //     }
    //   } else {
    //     const spinResult = (await this.vm.standardSpin()) as any;
    //     if (spinResult?.result) {
    //       this.vm.cancelSummonDialog();
    //       VueScrollTo.scrollTo("#video", 500, this.scrollOptions);
    //       await this.sleep(500);
    //       this.video.play();
    //       await this.sleep(2500);
    //       this.summoned = true;
    //       snackController.success(spinResult?.message);
    //     } else {
    //       snackController.error(spinResult?.message);
    //     }
    //   }
  }
}
